import axios from "axios";
import { loginStart, loginSuccess, loginFailure } from "./AuthenticationAction";
import notifyError from "../../components/notify/notifyError";

const url = "https://application.netfaraj-dz.com/api";

export const login = async (user, dispatch) => {
  dispatch(loginStart());
  try {
    const res = await axios.post(`${url}/user/login`, user);
    res.data.user.role === "admin" && dispatch(loginSuccess(res.data));
  } catch (error) {
    dispatch(loginFailure());
    notifyError("    The email address or password is incorrect.");
  }
};

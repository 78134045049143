import axios from "axios";
import {
  getPostsStart,
  getPostsSuccess,
  getPostsFailure,
  createPostStart,
  createPostSuccess,
  createPostFailure,
  updatePostStart,
  updatePostSuccess,
  updatePostFailure,
  deletePostStart,
  deletePostSuccess,
  deletePostFailure,
} from "./BlogAction";
import notifySuccess from "../../components/notify/notifySuccess";
import notifyError from "../../components/notify/notifyError";

const url = "https://application.netfaraj-dz.com/api";


export const getPosts = async (dispatch) => {
  dispatch(getPostsStart());
  try {
    const res = await axios.get(`${url}/news/`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatch(getPostsSuccess(res.data));
  } catch (error) {
    dispatch(getPostsFailure());
  }
};

export const createPost = async (post, dispatch) => {
  dispatch(createPostStart());
  try {
    const res = await axios.post(`${url}/news/`, post, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatch(createPostSuccess(res.data));
    notifySuccess("    The article has been successfully created.");
  } catch (error) {
    dispatch(createPostFailure());
    notifyError("    The article could not be created.");
  }
};

export const updatePost = async (post, dispatch) => {
  dispatch(updatePostStart());
  try {
    const res = await axios.put(`${url}/news/` + post._id, post, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatch(updatePostSuccess(res.data));
    notifySuccess("    The article has been successfully updated.");
  } catch (error) {
    dispatch(updatePostFailure());
    notifyError("    The article could not be updated.");
  }
};

export const deletePost = async (id, dispatch) => {
  dispatch(deletePostStart());
  try {
    await axios.delete(`${url}/news/` + id, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatch(deletePostSuccess(id));
    notifySuccess("    The article has been successfully deleted.");
  } catch (error) {
    dispatch(deletePostFailure());
    notifySuccess("    The article could not be deleted.");
  }
};

import axios from "axios";
import {
  getMoviesStart,
  getMoviesSuccess,
  getMoviesFailure,
  createMovieStart,
  createMovieSuccess,
  createMovieFailure,
  updateMovieStart,
  updateMovieSuccess,
  updateMovieFailure,
  deleteMovieStart,
  deleteMovieSuccess,
  deleteMovieFailure,
} from "./MovieAction";
import notifySuccess from "../../components/notify/notifySuccess";
import notifyError from "../../components/notify/notifyError";

const url = "https://application.netfaraj-dz.com/api";

export const getMovies = async (dispatch) => {
  dispatch(getMoviesStart());
  try {
    const res = await axios.get(`${url}/movie/`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatch(getMoviesSuccess(res.data));
  } catch (error) {
    dispatch(getMoviesFailure());
  }
};

export const createMovie = async (movie, dispatch) => {
  dispatch(createMovieStart());
  try {
    const res = await axios.post(`${url}/movie/`, movie, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatch(createMovieSuccess(res.data));
    notifySuccess("    The movie has been successfully created.");
  } catch (error) {
    dispatch(createMovieFailure());
    notifyError("    The movie could not be created.");
  }
};

export const updateMovie = async (movie, dispatch) => {
  dispatch(updateMovieStart());
  try {
    const res = await axios.put(`${url}/movie/` + movie._id, movie, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatch(updateMovieSuccess(res.data));
    notifySuccess("    The movie has been successfully updated.");
  } catch (error) {
    dispatch(updateMovieFailure);
    notifyError("    The movie could not be updated.");
  }
};

export const deleteMovie = async (ids, dispatch) => {
  dispatch(deleteMovieStart());
  try {
    await axios.delete(`${url}/movie/` + ids.movieId, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
      data: {
        ids: ids.categoryId && ids.categoryId,
      },
    });
    dispatch(deleteMovieSuccess(ids.movieId));
    notifySuccess("    The movie has been successfully deleted.");
  } catch (error) {
    dispatch(deleteMovieFailure());
    notifyError("    The movie could not be deleted.");
  }
};

import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCGtGEAeBiXaGnZqjOMoqJ6UXS6WPs3bus",
  authDomain: "laminestr-6789b.firebaseapp.com",
  projectId: "laminestr-6789b",
  messagingSenderId: "748213690137",
  appId: "1:748213690137:web:cb007a8c480b23dc762f88",
  measurementId: "G-9X3S62KKNX",
  storageBucket: "laminestr-6789b.appspot.com",
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export default storage;
